import { memo } from 'react';
import PropTypes from 'prop-types';

import { Consumer } from './ToastContext';

/**
 * This wrapper prevents render the 'component' when ToastProvider state is modified
 */
const Wrapper = memo(
  ({ component: Component, innerProps = undefined, ...rest }) => (
    <Component toast={rest} {...(innerProps || {})} />
  )
);

Wrapper.propTypes = {
  // eslint-disable-next-line react/require-default-props
  innerProps: PropTypes.object,
  component: PropTypes.func.isRequired
};

const withToast = WrappedComponent => props =>
  (
    <Consumer>
      {({ toasts, ...rest }) => (
        <Wrapper component={WrappedComponent} {...rest} innerProps={props} />
      )}
    </Consumer>
  );

export default withToast;
