import { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css, ClassNames, Global } from '@emotion/react';
import ReactModal from 'react-modal';

import colors from '../../../../shared/colors';
import fonts from '../../../../shared/fonts';
import { border, space } from '../../../../shared/spacing';
import { mediaQueries } from '../../../../shared/breakpoints';
import CloseIcon from '../../../../icons/Close';
import TextButton from '../../../TextButton';
import { noop } from '../../../../utils/fn';

const dialogGlobalStyles = css`
  .ReactModal__Body--open {
    overflow-y: hidden;
    ${mediaQueries.md(css`
      overflow-y: auto;
    `)};
  }
`;

const overlayStyle = css`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const dialogStyle = fullScreenOnMobile => css`
  background-color: ${colors.white};
  border-radius: ${border.radius};
  box-shadow: 0 0 17px 0 #bdbdbd;
  display: flex;
  flex-direction: column;
  outline: none;
  padding: ${space.x4};
  width: ${fullScreenOnMobile ? '100%' : 'auto'};
  ${fullScreenOnMobile ? '' : `max-width: calc(100% - ${space.x4});`};
  height: ${fullScreenOnMobile ? '100%' : 'auto'};
  ${fullScreenOnMobile
    ? ''
    : `
    max-height: 85%;
    max-height: calc(100% - ${space.x6});
  `}
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  ${mediaQueries.md(css`
    position: relative;
    width: 608px;
    height: auto;
    max-height: 85%;
    max-height: calc(100% - ${space.x6});
  `)};
`;

const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${fonts.GothamMedium};
  margin-bottom: ${space.x2};
`;

const StyledTextButton = styled(TextButton)`
  margin-left: ${space.x2};
`;

// eslint-disable-next-line react/require-default-props
const Dialog = props => {
  const {
    isOpen,
    closable = true,
    onRequestClose = noop,
    onOpen = noop,
    title = null,
    renderHeader = null,
    fullScreenOnMobile = false,
    closeButtonProps = undefined,
    renderContent = null,
    ...restProps
  } = props;

  const { children, ...rest } = props;

  return (
    <ClassNames>
      {({ css: innerCss }) => (
        <Fragment>
          <Global styles={dialogGlobalStyles} />
          <ReactModal
            isOpen={isOpen}
            onAfterOpen={onOpen}
            onRequestClose={onRequestClose}
            css={dialogStyle(fullScreenOnMobile)}
            overlayClassName={innerCss`
              ${overlayStyle}
            `}
            shouldCloseOnOverlayClick={closable}
            shouldCloseOnEsc={closable}
            role="dialog"
            contentLabel="dialog"
            ariaHideApp={false}
            {...restProps}
          >
            {renderHeader &&
              renderHeader({
                ...rest,
                closable,
                onRequestClose,
                onOpen,
                title,
                renderHeader,
                fullScreenOnMobile,
                renderContent
              })}
            {!renderHeader && (
              <DialogHeader>
                <span>{title}</span>
                {closable && (
                  <StyledTextButton
                    onClick={onRequestClose}
                    {...(closeButtonProps || {})}
                  >
                    <CloseIcon />
                  </StyledTextButton>
                )}
              </DialogHeader>
            )}
            {!renderContent && <div>{children}</div>}
            {renderContent &&
              renderContent({
                ...props,
                closable,
                onRequestClose,
                onOpen,
                title,
                renderHeader,
                fullScreenOnMobile,
                renderContent
              })}
          </ReactModal>
        </Fragment>
      )}
    </ClassNames>
  );
};

Dialog.propTypes = {
  /** Bool value to determine if dialog is opened */
  isOpen: PropTypes.bool.isRequired,
  /** Text to be shown on the header of the dialog */
  title: PropTypes.string,
  /** Overrides dialog's header  */
  renderHeader: PropTypes.func,
  /** Bool value to determine if the dialog can be closed by cliking on overlay or using ESC */
  closable: PropTypes.bool,
  /** Overrides dialog's content  */
  renderContent: PropTypes.func,
  /** Function to be called when dialog is opened */
  onOpen: PropTypes.func,
  /** Function to be called when the dialog is requested to be closed by clicking on overlay or pressing ESC */
  onRequestClose: PropTypes.func,
  /** Bool value to determine whether the dialog should be shown in fullscreen on mobile devices*/
  fullScreenOnMobile: PropTypes.bool,
  /** Content to be displayed inside the Dialog */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array
  ]).isRequired,
  /** Props to be passed to the close button */
  closeButtonProps: PropTypes.object
};

export default Dialog;
