import { createRef, Component } from 'react';
import PropTypes from 'prop-types';

import optionShape from '../../shapes/option';

import {
  defaultValue,
  findLabel,
  isValueControlled,
  measureSelectMenuWidth
} from '../../shared/utils/utils';

import {
  DropdownMenu,
  DropdownMenuItem,
  MenuPortal,
  SelectMenu,
  SelectMenuItem
} from './SelectMenu';
import {
  StyledDropdownContainer,
  StyledSelectContainer
} from './SelectControl.styles';
import SelectButton from './SelectButton';
import DropdownButton from './DropdownButton';

/**
 * Select control mode
 */
const modes = {
  SELECT: 'select',
  DROPDOWN: 'dropdown'
};
const modeArray = Object.keys(modes).map(key => modes[key]);

const OUTSIDE_EVENTS = ['click', 'touchstart', 'keyup'];

class SelectControl extends Component {
  static getDerivedStateFromProps(nextProps, state) {
    const { value } = nextProps;
    let nextState = {};

    if (isValueControlled(value) && value !== state.value) {
      nextState = { ...nextState, value };
    }

    return Object.keys(nextState).length > 0 ? nextState : null;
  }

  state = {
    isOpen: false,
    // eslint-disable-next-line react/no-unused-state
    isActive: false,
    value: defaultValue(
      this.props.value || undefined,
      this.props.options || [],
      this.props.placeholder || ''
    )
  };

  constructor(props) {
    super(props);
    this.selectButtonRef = createRef();
    this.controlRef = createRef();
  }

  componentWillUnmount() {
    this.removeEvents();
  }

  handleFocus = () => {
    this.setState(() => ({
      // eslint-disable-next-line react/no-unused-state
      isActive: true
    }));
  };

  handleBlur = () => {
    this.setState(() => ({
      // eslint-disable-next-line react/no-unused-state
      isActive: false
    }));
  };

  handleSelectButtonClick = () => {
    this.toggle();
  };

  handleOnSelect = value => {
    this.select(value);
    this.close();
  };

  handleDocumentClick = () => {
    this.setState(prevState =>
      !prevState.isActive ? { isOpen: false } : null
    );
  };

  get isValueControlled() {
    return isValueControlled(this.props.value || undefined);
  }

  get selectMenuTopOffset() {
    const index = this.selectedValueIndex;
    return index > 0 ? this.selectButtonHeight * index * -1 : 0;
  }

  get dropdownMenuTopOffset() {
    return this.selectButtonHeight;
  }

  get selectButtonHeight() {
    return this.selectButtonRef.current
      ? this.selectButtonRef.current.clientHeight
      : 0;
  }

  get selectButtonWidth() {
    return this.selectButtonRef.current
      ? this.selectButtonRef.current.clientWidth
      : 0;
  }

  get selectedValueIndex() {
    const { options = [] } = this.props;
    const { value } = this.state;
    return options.findIndex(option => option.value === value);
  }

  get caption() {
    const { value } = this.state;
    const { options = [], placeholder = '' } = this.props;

    if (value === null) {
      if (placeholder) return placeholder;
      return '';
    }
    return findLabel(value, options);
  }

  select = value => {
    if (this.isValueControlled) {
      this.props.onChange(value);
    } else {
      this.setState(() => ({
        value
      }));
    }
  };

  toggle = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  close = () => {
    this.setState(() => ({
      isOpen: false
    }));
  };

  addEvents() {
    OUTSIDE_EVENTS.forEach(event =>
      document.addEventListener(event, this.handleDocumentClick, true)
    );
  }

  removeEvents() {
    OUTSIDE_EVENTS.forEach(event =>
      document.removeEventListener(event, this.handleDocumentClick, true)
    );
  }

  manageEvents() {
    const { isOpen } = this.state;

    if (isOpen) {
      this.addEvents();
    } else {
      this.removeEvents();
    }
  }

  renderSelectButton() {
    const { isOpen } = this.state;
    const { selectProps = undefined } = this.props;
    return (
      <SelectButton
        ref={this.selectButtonRef}
        isOpen={isOpen}
        caption={this.caption}
        onClick={this.handleSelectButtonClick}
        {...(selectProps || {})}
      />
    );
  }

  renderDropdownButton() {
    const { isOpen } = this.state;
    const { selectProps = undefined } = this.props;
    return (
      <DropdownButton
        ref={this.selectButtonRef}
        isOpen={isOpen}
        caption={this.caption}
        onClick={this.handleSelectButtonClick}
        {...(selectProps || {})}
      />
    );
  }

  renderButton() {
    let button;
    const { mode = modes.SELECT } = this.props;
    switch (mode) {
      case modes.SELECT:
        button = this.renderSelectButton();
        break;
      case modes.DROPDOWN:
        button = this.renderDropdownButton();
        break;
      default:
        throw new Error(`Value ${mode} not handled`);
    }

    return button;
  }

  renderSelectMenu() {
    const {
      options = [],
      isOptionDisabled = undefined,
      menuProps = undefined
    } = this.props;
    const { value, isOpen } = this.state;
    const selectMenuWidth = measureSelectMenuWidth(options);
    const selectButtonWidth = this.selectButtonWidth;

    return (
      <SelectMenu
        isOpen={isOpen}
        top={this.selectMenuTopOffset}
        minWidth={Math.max(selectMenuWidth, selectButtonWidth)}
        {...(menuProps || {})}
      >
        {options.map(option => (
          <SelectMenuItem
            key={option.value}
            option={option}
            isDisabled={isOptionDisabled && isOptionDisabled(option)}
            isSelected={option.value === value}
            onSelect={this.handleOnSelect}
          />
        ))}
      </SelectMenu>
    );
  }

  renderDropdownMenu() {
    const {
      options = [],
      isOptionDisabled = undefined,
      menuProps = undefined
    } = this.props;
    const { value, isOpen } = this.state;

    return (
      <DropdownMenu
        isOpen={isOpen}
        top={this.dropdownMenuTopOffset}
        {...(menuProps || {})}
      >
        {options.map(option => (
          <DropdownMenuItem
            key={option.value}
            option={option}
            isDisabled={isOptionDisabled && isOptionDisabled(option)}
            isSelected={option.value === value}
            onSelect={this.handleOnSelect}
          />
        ))}
      </DropdownMenu>
    );
  }

  renderMenu() {
    const {
      mode = modes.SELECT,
      portalTargetSelector = '',
      options = []
    } = this.props;
    const { isOpen } = this.state;

    if (!isOpen) return null;

    let menu;
    let minWidthInPortal;
    switch (mode) {
      case modes.SELECT:
        menu = this.renderSelectMenu();
        minWidthInPortal = measureSelectMenuWidth(options);
        break;
      case modes.DROPDOWN:
        menu = this.renderDropdownMenu();
        minWidthInPortal =
          this.selectButtonRef.current.getBoundingClientRect().width;
        break;
      default:
        throw new Error(`Value ${mode} not handled`);
    }

    return portalTargetSelector ? (
      <MenuPortal
        parentElement={this.controlRef}
        portalTargetSelector={portalTargetSelector}
        minWidth={minWidthInPortal}
      >
        {menu}
      </MenuPortal>
    ) : (
      menu
    );
  }

  renderSelect() {
    return (
      <StyledSelectContainer
        ref={this.controlRef}
        role="button"
        tabIndex="0"
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
      >
        {this.renderButton()}
        {this.renderMenu()}
      </StyledSelectContainer>
    );
  }

  renderDropdown() {
    return (
      <StyledDropdownContainer
        ref={this.controlRef}
        role="button"
        tabIndex="0"
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
      >
        {this.renderButton()}
        {this.renderMenu()}
      </StyledDropdownContainer>
    );
  }

  render() {
    this.manageEvents();

    const { mode = modes.SELECT } = this.props;
    let control;
    switch (mode) {
      case modes.SELECT:
        control = this.renderSelect();
        break;
      case modes.DROPDOWN:
        control = this.renderDropdown();
        break;
      default:
        throw new Error(`Value ${mode} not handled`);
    }

    return control;
  }
}

/* eslint-disable react/require-default-props */
SelectControl.propTypes = {
  mode: PropTypes.oneOf(modeArray),
  options: PropTypes.arrayOf(optionShape),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  isOptionDisabled: PropTypes.func,
  onChange: PropTypes.func,
  portalTargetSelector: PropTypes.string,
  selectProps: PropTypes.object,
  menuProps: PropTypes.object
};

export default SelectControl;
