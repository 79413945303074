import { Fragment, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import NativeSelect from '../NativeSelect/NativeSelect';
import optionShape from '../../shapes/option';

import debounceThrottle from '../../../../utils/debounce-throttle';
import device from '../../../../shared/device';

import SelectControl from './SelectControl';
import {
  modeTypes,
  modeTypesArray,
  touchModeTypes,
  touchModeTypesArray
} from './mode-types';

/**
 * Calculates the final mode of the select
 * @param mode
 * @param touchMode
 * @returns {*}
 */
function calculateMode(mode, touchMode) {
  if (touchMode === touchModeTypes.INHERITED) return mode;

  return device.isTouchDevice() ? modeTypes.NATIVE : mode;
}

/**
 * Select wrapper over the real control
 */
function Select({
  mode: propMode = modeTypes.SELECT,
  touchMode = touchModeTypes.NATIVE,
  placeholder = '',
  isOptionDisabled = undefined,
  value = undefined,
  onChange = undefined,
  options = [],
  portalTargetSelector = '',
  ...htmlProps
}) {
  const [mode, setMode] = useState(() => calculateMode(propMode, touchMode));

  // Update mode when props change
  useEffect(() => {
    setMode(calculateMode(propMode, touchMode));
  }, [propMode, touchMode]);

  const setModeHandler = useCallback(() => {
    setMode(calculateMode(propMode, touchMode));
  }, [propMode, touchMode]);

  const setModeProxy = useCallback(
    () => debounceThrottle(setModeHandler)(),
    [setModeHandler]
  );

  useEffect(() => {
    window.addEventListener('resize', setModeProxy);
    return () => window.removeEventListener('resize', setModeProxy);
  }, [setModeProxy]);

  const isNativeMode = mode === modeTypes.NATIVE;

  return (
    <Fragment>
      {isNativeMode ? (
        <NativeSelect
          placeholder={placeholder}
          isOptionDisabled={isOptionDisabled}
          value={value}
          dropdownButton={propMode === modeTypes.DROPDOWN}
          onChange={onChange}
          options={options}
          {...htmlProps}
        />
      ) : (
        <SelectControl
          mode={mode}
          placeholder={placeholder}
          isOptionDisabled={isOptionDisabled}
          value={value}
          onChange={onChange}
          options={options}
          portalTargetSelector={portalTargetSelector}
          {...htmlProps}
        />
      )}
    </Fragment>
  );
}

Select.propTypes = {
  /** Mode in which the select is displayed */
  mode: PropTypes.oneOf(modeTypesArray),
  /** Mode in which the select is displayed on touch device */
  touchMode: PropTypes.oneOf(touchModeTypesArray),
  /** Array of options values */
  options: PropTypes.arrayOf(optionShape),
  /** Controlled prop for the value of the select */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The initial placeholder for the select */
  placeholder: PropTypes.string,
  /** Function which receives every value of the select to check if is disabled or not */
  isOptionDisabled: PropTypes.func,
  /** Callback for the value change */
  onChange: PropTypes.func,
  /**
   * If the select should use a portal anchored to the selector, body (useful for overflow containers)
   *
   * This options is disabled when the select is in 'native' mode
   */
  portalTargetSelector: PropTypes.string
};

Select.modeTypes = modeTypes;
Select.modeTypesArray = modeTypesArray;
Select.touchModeTypes = touchModeTypes;
Select.touchModeTypesArray = touchModeTypesArray;

export default Select;
