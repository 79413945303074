import { createRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import optionShape from '../../shapes/option';
import Icon from '../../../Icon/Icon';
import { defaultValue } from '../../shared/utils/utils';

import { noop } from '../../../../utils/fn';

import {
  ArrowIconWrapper,
  NativeSelectContainer,
  StyledSelect
} from './NativeSelect.styles';

function isValueControlled(value) {
  return value !== undefined;
}

const NativeSelect = ({
  dropdownButton = false,
  options = [],
  value: propValue = undefined,
  placeholder = '',
  isOptionDisabled = undefined,
  onChange = noop,
  ...htmlProps
}) => {
  const selectRef = createRef();
  const [stateValue, setStateValue] = useState(
    defaultValue(propValue, options, placeholder)
  );

  useEffect(() => {
    if (isValueControlled(propValue) && propValue !== stateValue) {
      setStateValue(propValue);
    }
  }, [propValue, stateValue]);

  const handleOnChange = e => {
    const value = e.target.value;
    if (isValueControlled(propValue)) {
      onChange(value);
    } else {
      setStateValue(value);
      onChange(value);
    }
  };

  const shouldRenderPlaceholder = () => {
    return stateValue === null && placeholder;
  };

  const { onChange: _onChange, ...rest } = htmlProps; // onChange event cannot be passed down

  return (
    <NativeSelectContainer isDropdownButton={dropdownButton}>
      <ArrowIconWrapper>
        <Icon name={'angleDown'} />
      </ArrowIconWrapper>
      <StyledSelect
        ref={selectRef}
        isDropdownButton={dropdownButton}
        value={stateValue || ''}
        onChange={handleOnChange}
        {...rest}
      >
        {shouldRenderPlaceholder() && (
          <option key="empty" value="">
            {placeholder}
          </option>
        )}
        {options.map(option => (
          <option
            key={option.value}
            value={option.value}
            disabled={isOptionDisabled && isOptionDisabled(option)}
          >
            {option.label}
          </option>
        ))}
      </StyledSelect>
    </NativeSelectContainer>
  );
};

NativeSelect.propTypes = {
  /** Whether the button of the select should be displayed as dropdown */
  dropdownButton: PropTypes.bool,
  /** Array of options values */
  options: PropTypes.arrayOf(optionShape),
  /** Controlled prop for the value of the select */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The initial placeholder for the select */
  placeholder: PropTypes.string,
  /** Function which receives every value of the select to check if is disabled or not */
  isOptionDisabled: PropTypes.func,
  /** Callback for the value change */
  onChange: PropTypes.func
};

export default NativeSelect;
